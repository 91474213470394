// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getNavbarData = createAsyncThunk(
  "navbar/getNavbarData",
  async (params) => {
    const response = await axios.get("/metadata/v2/navItems", { params })
    return {
      data: response.data
    }
  }
)

export const egnyteSlice = createSlice({
  name: "navbar",
  initialState: {
    loading: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    filterData: []
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNavbarData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.loading = false
    })
  }
})

export const { setLoading } = egnyteSlice.actions

// ** Handle Bookmarks & Main Search Queries
export const handleSearchQuery = (val) => (dispatch) =>
  dispatch({ type: "HANDLE_SEARCH_QUERY", val })

export default egnyteSlice.reducer
