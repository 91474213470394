/**
 * Verify the current token is valid
 */
// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import { any } from "prop-types"
import useJwt from "@src/auth/jwt/useJwt"
const config = useJwt.jwtConfig

export const verifyJWT = createAsyncThunk(
  "verifyAuth/verifyJWT",
  async () => {
    try {
      const token = localStorage.getItem(config.storageTokenKeyName)
      const response = await axios.post("/puboauth/verify-token", { token })
      const resVal = JSON.stringify(response.data.tokenStatus)
      if (resVal.includes('expired')) {
        window.location.replace('/login')
      }
      return response.data
    } catch (err) {
      const customError = {
        message: err.response.data.errorMessage
      }
      throw customError
    }
  }
)

export const verifySlice = createSlice({
  name: "verifyAuth",
  initialState: {
    data: any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyJWT.fulfilled, (state, action) => {
        if (action !== undefined && state !== undefined) {
           state.data = action.data
        }
    })
  }
})

export default verifySlice.reducer
