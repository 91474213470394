// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// Get related data
export const getData = createAsyncThunk(
  "nestedDocuments/getData",
  async (params) => {
    const response = await axios.post("/pubapi/v2/search-documents", {
      ...params
    })

    return {
      allData: response.data.results,
      data: response.data.results,
      totalPages: 1,
      params
    }
  }
)

export const nestedDocumentsSlice = createSlice({
  name: "nestedDocuments",
  initialState: {
    backFromDetailView: false,
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {
    setBackFromDetailView: (state, action) => {
      state.backFromDetailView = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
  }
})

export const { setBackFromDetailView } = nestedDocumentsSlice.actions

export default nestedDocumentsSlice.reducer
