// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getData = createAsyncThunk("egnyte/getData", async (params) => {
  const response = await axios.get("/pubapi/v3/search", { params })
  return {
    allData: response.data.results,
    data: response.data.results,
    totalPages: response.data.totalPages,
    totalDocs: response.data.totalCount,
    totalCount: response.data.totalCount,
    params
  }
})

export const getFilterData = createAsyncThunk(
  "egnyte/getFilterData",
  async () => {
    const response = await axios.get("/pubapi/v1/properties/namespace")
    return {
      filterData: response.data
    }
  }
)

export const egnyteSlice = createSlice({
  name: "egnyte",
  initialState: {
    loading: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    filterData: []
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalCount
      state.totalPages = action.payload.totalPages
      state.totalDocs = action.payload.totalDocs
      state.loading = false
    })
    builder.addCase(getFilterData.fulfilled, (state, action) => {
      state.filterData = action.payload.filterData.namespaceInfo
      state.contractors = action.payload.filterData.contractors
      state.loading = false
    })
  }
})

export const { setLoading } = egnyteSlice.actions

export default egnyteSlice.reducer
