// ** Logo
import logo35Bio from "../../../assets/images/logo/ThirtyFiveBio_logo_animated.gif"

const logo = (instance) => {
  switch (instance) {
    case "35Bio":
      return logo35Bio
    default:
      return logo35Bio
  }
}

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={logo(process.env.REACT_APP_NAME)}
        alt="loading..."
      />
      {/* <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div> */}
    </div>
  )
}

export default SpinnerComponent
